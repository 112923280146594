<template>
  <div class="d-flex flex-stack" style="padding-bottom:10px;">
    <div class="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
    </div>
    <div class="col-md-6" align="right">
      <div class="me-4">
        <el-input
            v-model="search.searchValue"
            placeholder="Type to search"
            class="col-md-10"
            style="padding-right:10px;"/>
        <button
            @click="openCreateAgent"
            class="btn btn-sm btn-primary"
            id="agent_create_button"
        >
        Create
        </button>
      </div>
    </div>
  </div>
    <div class="card mb-5 mb-xl-10" id="agent_list">
        <el-table :data="pagedTableDate" style="width: 100%;text-align:center;" @row-click="selectAgent">
            <el-table-column prop="company_name" label="Company Name"></el-table-column>
            <el-table-column prop="name" label="Name"></el-table-column>
            <el-table-column prop="phone" label="Phone"></el-table-column>
            <el-table-column prop="email" label="Email"></el-table-column>
            <el-table-column prop="student_count" label="No of Students"></el-table-column>
            <el-table-column 
                prop="is_active" 
                label="Status"
                :filters="[
                    { text: 'Active', value: true },
                    { text: 'Inactive', value: false },
                ]"
                :filter-method="filterActive">
                <template v-slot="scope">          
                <span v-if="scope.row.is_active" class="badge-light-success badge fs-8 fw-bolder my-2">Active</span>
                <span v-else class="badge-light-danger badge fs-8 fw-bolder my-2">Inactive</span>
                </template>
            </el-table-column>
        </el-table>
        <div align="right">
            <el-pagination 
              layout="sizes, prev, pager, next" 
              v-model:page-size="pageSize" 
              :page-sizes="pageSizes"
              :small="small"
              :disabled="disabled"
              :background="background"
              :total="getFilteredAgentList().length" 
              @size-change="handleSizeChange"
              @current-change="setPage">
            </el-pagination>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, ref, reactive, onBeforeMount } from "vue";
import Vue from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import bootstrap from "bootstrap";
import ApiService from "@/core/services/ApiService";
import router from "@/router";

export default defineComponent({
  name: "agent-list",
  components: {
  },
  setup() {
    const agents = reactive<any>([]);

    const getAgents = async () => {
        await new Promise((resolve, reject) => {  
            ApiService.setHeader();          
            ApiService.get("agent")
                .then(({ data }) => {
                    agents.value = data.data;
                    resolve(data.data);
                })
                .catch(({ response }) => {
                    reject(new Error ('getAgents error => '+response.data.errors));
                });
        });
    }

    const getFilteredAgentList = () =>{
      if(agents.value){
        return agents.value.filter(data => !search 
                                  || data.name?.toLowerCase().includes(search.searchValue.toLowerCase())
                                  || data.phone?.toLowerCase().includes(search.searchValue.toLowerCase())
                                  || data.email?.toLowerCase().includes(search.searchValue.toLowerCase()));
      }

      return [];
    }

    const pageSize = ref(20);
    let page = reactive({pageValue : 1});

    const pageSizes = computed(()=>{
      let sizes = [20];
      let curr_length = getFilteredAgentList().length;

      if(curr_length > 20){
        sizes.push(50);
        if(curr_length >50){
          let i = 0;
          while(i<curr_length){
            i+=100;
            sizes.push(i);
          }
        }
      }

      return sizes;
    })    

    const pagedTableDate = computed(()=>{
      if(agents.value){
          return getFilteredAgentList().slice(pageSize.value * page.pageValue - pageSize.value, pageSize.value * page.pageValue);
      }
      else{
          return [];
      }
     })

    const search = reactive({searchValue : ''});

    const setPage = (val) => {
      page.pageValue = val;
    }

    const handleSizeChange = (val: number) => {
      pageSize.value = val; 
    }

    const filterActive = (value, row) => {
      return row.is_active === value
    }

    const selectAgent= (row) =>{
      router.push({ name: "agent-detail", params: { id: row.id } });
    }

    const openCreateAgent= () =>{
      router.push({ name: "agent-detail", params: { id: 0 } });
    }

    onBeforeMount(async() => {
      await getAgents();
    })

    onMounted(() => {
      setCurrentPageBreadcrumbs("Agent", ["Management"]);
    });

    return {
      setPage,
      pagedTableDate,
      pageSize,
      search,
      filterActive,
      selectAgent,
      agents,
      openCreateAgent,
      pageSizes,
      handleSizeChange,
      getFilteredAgentList
    };
  },
});
</script>

<style>
.el-table th>.cell.highlight{
    background-color:transparent;
    padding: 0px;
}

.cell {
    text-align:center;
}
</style>
